exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-contact-us-template-tsx": () => import("./../../../src/templates/contact-us-template.tsx" /* webpackChunkName: "component---src-templates-contact-us-template-tsx" */),
  "component---src-templates-course-template-tsx": () => import("./../../../src/templates/course-template.tsx" /* webpackChunkName: "component---src-templates-course-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/home-template.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "slice---src-slices-footer-footer-legals-tsx": () => import("./../../../src/slices/Footer/footer-legals.tsx" /* webpackChunkName: "slice---src-slices-footer-footer-legals-tsx" */),
  "slice---src-slices-footer-footer-slice-tsx": () => import("./../../../src/slices/Footer/footer-slice.tsx" /* webpackChunkName: "slice---src-slices-footer-footer-slice-tsx" */),
  "slice---src-slices-main-menu-main-menu-courses-tsx": () => import("./../../../src/slices/MainMenu/MainMenuCourses.tsx" /* webpackChunkName: "slice---src-slices-main-menu-main-menu-courses-tsx" */),
  "slice---src-slices-main-menu-main-menu-services-tsx": () => import("./../../../src/slices/MainMenu/MainMenuServices.tsx" /* webpackChunkName: "slice---src-slices-main-menu-main-menu-services-tsx" */),
  "slice---src-slices-main-menu-main-menu-tsx": () => import("./../../../src/slices/MainMenu/MainMenu.tsx" /* webpackChunkName: "slice---src-slices-main-menu-main-menu-tsx" */),
  "slice---src-slices-pricing-pricing-slice-tsx": () => import("./../../../src/slices/pricing/PricingSlice.tsx" /* webpackChunkName: "slice---src-slices-pricing-pricing-slice-tsx" */)
}

