import { GatsbyBrowser } from "gatsby";
import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";

import { ConfigCookieConsent } from "./src/modules/CookieConsent/CookieConsent";

export const wrapPageElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  useEffect(() => {
    CookieConsent.run(ConfigCookieConsent);
  }, []);
  return element;
};
